:root {
    --color-primary: #C8E4B2;
    --color-primary-darker1: #a0dd71;
    --color-secondary: #e9f5ed;
    --color-secondary-darker1: #bff0cf;
    --color-alt1: #FFD9B7;
    --color-alt2: #20643f;
    --color-alt2-darker: #4b7e62;
    --color-danger: #dc3545;
    --color-danger-darker1: #af3541;
    --color-text-primary: #333232;
    --color-link: #007bff;
    --color-link-hover: #0056b3;
  }